.slider-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 3rem 0;
}

.slider {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  height: 28px;
  border-radius: 70px;
  cursor: pointer;
  border: 1px solid #000000;
  overflow: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slider::-webkit-slider-runnable-track {
    height: 28px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    color: #13bba4;
  }

  .slider::-webkit-slider-thumb {
    height: 28px;
    width: 28px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    background: #efc94000;
  }
}

.slider-tooltip {
  position: absolute;
  top: -35px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-tooltip-text {
  background-color: #f4c542;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4c542;
  margin-top: -3px;
}

.slider-values {
  position: absolute;
  left: 0;
  right: 0;
  top: 53%;
}

.slider-mark {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -17%);
}

.dot {
  height: 1.1rem;
  width: 1.1rem;
  background-color: #00a651;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.dot.active {
  opacity: 1;
}

.mark-label {
  width: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  display: block;
  font-size: 12px;
  color: #333;
}

@media (max-width: 650px) {
  .mark-label {
    visibility: hidden;
  }

  .headerNse {
    visibility: hidden;
    height: 0;
    width: 0 !important ;
  }
}
