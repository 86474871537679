body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background: #f3f3f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --active: #faca3a;
  --primary: #198754;
  --primary-light: #59c6bc;
}

h1 {
  font-weight: 600;
  margin: 0px;
}
h2 {
  font-weight: 600;
  margin: 0px;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #777;
  margin: 0px;
}
.h5_font_family {
  color: #000;
  font-size: 16px;
  margin: 0px;
  font-family: "Jost", sans-serif;
  padding-bottom: 3px;
}

.flex-grow-1{
  flex-grow: 1;
}

.input {
  width: 100%;
  padding: 12px;
  border-radius: 13px;
  border: 1px solid #f8bd0c;
  background: #ffffff;
  font-weight: 600;
  font-size: 13px;
  color: #1c1d1f;
  outline: none;
  resize: none;
  overflow: hidden;
}

.input:focus{
  color: #1c1d1f;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.5);
}

.input::placeholder{
  color: rgb(108, 111, 118);
}

button[type="primary"] {
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #f8bd0c;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  font-size: .9rem;
}

button[type="primary"]:hover {
  transform: scale(1.03);
  background-color: #ffffff;
  border: 2px solid #f8bd0c;
}

button[type="secondry"] {
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #ffffff;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  cursor: pointer;
  font-size: .9rem;
}

button[type="secondry"]:hover {
  transform: scale(1.03);
  background-color: #f8bd0c;
  border: 2px solid #f8bd0c;
}

button[type="disabled"] {
  color: #fff;
  font-size: 15px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border: 0px;
  border-radius: 50px;
  background-color: #C7C7C7;
}

.spacing_sm {
  margin-top: 12px;
  margin-bottom: 12px;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.bg-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("src/assets/bg-01.webp");
  background-size: 100% 100%;
}
.register .bg-container {
  padding-bottom: 100px;
  background-size: 100% auto;
}
.ai-center {
  align-items: center;
}
.jc-center {
  justify-content: center;
}

.flex-grow-1{
  flex-grow: 1;
}

.mainCard {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #474747;
  background-color: #fff;
  margin: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
}
.card-plan {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}
.elevated {
  box-shadow: 0px 0px 30px #0000000d;
}
.elevated_deep {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}
.clickable {
  cursor: pointer;
}

@keyframes color-up {
  to {
    color: #000;
    background-color: var(--active);
  }
}
.m-12 {
  margin: 12px;
}
.m-24 {
  margin: 24px;
}
.p-12 {
  padding: 12px;
}
.p-24 {
  padding: 24px;
}
/* css for Links */
a {
  text-decoration: none;
  color: #777;
}
/* css for slider*/
.slick-prev::before,
.slick-next::before {
  display: none;
}
.zoom-in {
  transition: all 0.3s;
}
.card:hover .zoom-in,
.card:active .zoom-in,
.card-plan:hover .zoom-in,
.card-plan:active .zoom-in,
.card-plan:focus .zoom-in,
.card:focus .zoom-in {
  scale: 1.1;
}
.bg-black {
  background: #000;
}
.dashboard-heading {
  text-align: center;
  font-size: 35px;
  padding: 40px 0 10px 0;
  color: #2d8b82;
}
.title_font {
  font-family: "Jost", sans-serif;
  position: relative;
}
.title_underline {
  margin-bottom: 30px;
}
.title_underline::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.title_underline::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2d8b82;
  bottom: 0;
  left: calc(50% - 20px);
}
/* css for mui input */
.MuiFilledInput-root {
  width: 100%;
  background-color: transparent !important;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
}
.MuiFilledInput-root::before {
  border-bottom: none !important;
}
.tab_container {
  width: 100%;
  display: flex;
  border-radius: 50;
  overflow: hidden;
  text-align: center;
  margin: auto;
  border-radius: 25px;
  line-height: 25px;
}
.schedule .tab_container {
  line-height: 26px;
  width: 500px;
}
.register select {
  -webkit-appearance: none;
}

.legal-page .container {
  margin: 0 50px;
  border-radius: 10px;
  text-align: justify;
  line-height: 30px;
}
.google_login_button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: rgb(220, 40, 40);
  color: #fff;
}
.google_login_button img {
  padding: 0 5px;
  margin-right: 5px;
  filter: invert(1);
  border-right: 2px solid #000;
}
.google_login_button:hover {
  background: rgb(182, 30, 30);
}

@media screen and (max-width: 480px){
  .mainCard .imgWrap{
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .container{
    height: 100vh;
  }
}

@media screen and (orientation: landscape) {
  .container{
    height: 100vh;
  }
}