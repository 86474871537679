.dashboard {
  margin-left: 250px;
  background: #f3f3f9;
}
.toggle_button img {
  position: absolute;
  right: 20px;
}
/* dashboard navbar */
.dashboard_navbar {
  margin: 0;
  margin-left: 250px;
  text-align: -webkit-right;
  align-items: center;
  background: #fff;
  padding: 5px 20px;
  position: relative;
  box-shadow: 1px 5px 4px #ddd;
}
.dashboard_navbar .dropdown_button {
  position: relative;
  width: 150px;
  border: 2px solid #ddd;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
  background: rgb(241, 241, 241);
}
.dashboard_navbar .dropdown_button > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}
.dashboard_navbar .dropdown_button > div:first-child > div {
  overflow-x: auto;
}
.dashboard_navbar .dropdown_button .dropdown {
  position: absolute;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background: white;
  width: 150px;
  margin-top: 15px;
  transition: all 1s;
  right: 0;
  background: rgb(247, 247, 247);
  z-index: 1;
}
.dashboard_navbar .dropdown_button .dropdown div {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid #ddd;
}
.dashboard_navbar .dropdown_button .dropdown div:hover {
  border-bottom: 2px solid black;
}
/* class curriculum */
.lang_button a {
  position: absolute;
  top: -54px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background: #198754;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: 2px solid #198754;
}
.lang_button:hover a {
  opacity: 1;
}
.lang_button:hover a:hover {
  background: transparent;
  color: #198754;
}
.contact_button {
  text-align: center;
  width: 250px;
  margin: auto;
  background: #fff;
  padding: 14px 0;
  border: 2px solid #2d8b82;
  border-radius: 5px;
  font-size: 15px;
  color: #2d8b82;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}
.contact_button:hover {
  color: #fff;
  background: #2d8b82;
}
/* register page */
.register_button {
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #fff;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  cursor: pointer;
}
.register_button:hover {
  transform: scale(1.03);
  background-color: #f8bd0c;
  border: 2px solid #f8bd0c;
}
